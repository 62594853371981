var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('HeaderContent',{attrs:{"label":"Manage Hashtag","marginBottom":"16"}}),_c('div',[_c('div',{staticClass:"d-flex justify-space-between"},[_c('div',{staticStyle:{"width":"145px"}},[_c('v-select',{staticClass:"font-12",attrs:{"items":_vm.listCountry,"item-text":"label","item-value":"value","solo":"","dense":"","hide-details":"","return-object":"","dark":"","background-color":"success"},model:{value:(_vm.filterCountryLocal),callback:function ($$v) {_vm.filterCountryLocal=$$v},expression:"filterCountryLocal"}})],1),_c('custom-button',{attrs:{"dark":"","color":"secondary"},on:{"click":function($event){_vm.dialogCreateTrending = true}}},[_vm._v(" Buat Trending Baru ")])],1),_c('div',{staticClass:"d-flex align-center font-14 mt-5",staticStyle:{"width":"145px"}},[_c('span',{staticStyle:{"color":"#9b9b9b"}},[_vm._v("Sort")]),_c('div',[_c('v-select',{staticClass:"ml-2 font-12",attrs:{"items":_vm.listSort,"outlined":"","dense":"","hide-details":"","background-color":"whitesnow"},model:{value:(_vm.sort),callback:function ($$v) {_vm.sort=$$v},expression:"sort"}})],1)]),_c('div',{staticClass:"mt-4"},[_c('v-data-table',{attrs:{"headers":_vm.tableHeaders,"items":_vm.tableItems,"hide-default-footer":"","loading":_vm.loadingTableItems},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',[_c('td',{staticClass:"font-12 font-weight-medium grey--text"},[_vm._v(" "+_vm._s(_vm.formatDate(item.createAt))+" ")]),_c('td',{staticClass:"font-12 font-weight-medium grey--text"},[_vm._v(" "+_vm._s(_vm.formatDate(item.createAt, true))+" ")]),_c('td',{staticClass:"font-12 font-weight-medium grey--text"},[_vm._v(" "+_vm._s(item.totalQty)+" ")]),_c('td',{staticClass:"font-12 font-weight-medium grey--text"},[_vm._v(" "+_vm._s(item.hashtags.length)+" ")]),_c('td',{staticClass:"font-12 font-weight-medium",class:{
                  'green--text': item.status == 'ACTIVE',
                  'grey--text': item.status != 'ACTIVE',
                }},[_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.status.toLowerCase())+" ")])]),_c('td',{staticClass:"d-flex justify-end align-center pr-4"},[_c('v-btn',{staticClass:"text-capitalize secondary--text font-weight-medium",attrs:{"small":"","depressed":"","color":"whitesnow"},on:{"click":function($event){return _vm.moveTo('/manage/hashtag/detail', item, true)}}},[_vm._v("Detail")])],1)])]}}])}),_c('div',{staticClass:"d-flex justify-end mt-10"},[_c('v-pagination',{staticClass:"d-flex justify-end",attrs:{"color":"secondary","length":_vm.pageCount,"total-visible":"5","prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right"},on:{"input":_vm.changePage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1),_c('v-dialog',{attrs:{"width":"343"},model:{value:(_vm.dialogCreateTrending),callback:function ($$v) {_vm.dialogCreateTrending=$$v},expression:"dialogCreateTrending"}},[_c('v-card',[_c('div',{staticClass:"pt-5 px-5 pb-3"},[_c('div',[_c('span',{staticClass:"font-24 font-weight-medium"},[_vm._v("Buat Trending Baru")]),_c('br'),_c('span',{staticClass:"font-10 font-weight-medium mt-3"},[_vm._v(" Pilih channel untuk menentukan dimana formasi diterapkan ")])]),_c('div',{staticClass:"d-flex justify-space-between align-center mt-6"},_vm._l((_vm.itemsTrendingCountry),function(item,idx){return _c('div',{key:idx,staticClass:"d-flex justify-center align-center box-country",class:{
                  'box-country-selected':
                    _vm.createTrendingCountry &&
                    _vm.createTrendingCountry.value == item.value,
                },on:{"click":function($event){_vm.createTrendingCountry = item}}},[_c('div',{staticClass:"d-flex flex-column justify-center align-center text-center"},[(item.value == 'indonesia')?_c('v-img',{attrs:{"src":require("@/assets/flag_id.png"),"width":"32px"}}):(item.value == 'china')?_c('v-img',{attrs:{"src":require("@/assets/flag_cn.png"),"width":"32px"}}):_vm._e(),_c('span',{staticClass:"font-10 font-weight-medium"},[_vm._v(_vm._s(item.label))])],1)])}),0)]),_c('v-divider'),_c('div',{staticClass:"d-flex justify-space-between pt-3 px-5 pb-5"},[_c('custom-button',{staticStyle:{"width":"145px","border-radius":"8px"},on:{"click":function($event){_vm.dialogCreateTrending = false}}},[_vm._v(" Cancel ")]),_c('custom-button',{staticStyle:{"width":"145px","border-radius":"8px"},attrs:{"color":"secondary","disabled":_vm.createTrendingCountry == null},on:{"click":_vm.handleCreateTrending}},[_vm._v(" Buat ")])],1)],1)],1)],1),_c('v-snackbar',{attrs:{"top":"","right":"","color":"primary","timeout":3000},model:{value:(_vm.alertFailed),callback:function ($$v) {_vm.alertFailed=$$v},expression:"alertFailed"}},[_vm._v(" Error: "+_vm._s(_vm.dataFailed.message)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }